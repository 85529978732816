import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@core/auth';
import { switchMap, shareReplay, map, pluck, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private cache$: any = {};

  public isUserApproved: boolean;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public searchUserByKeyword(keyword: string, status: string): Observable<any[]> {
    if (!keyword.trim()) {
      return of([]);
    }
    if (status == null) {
      return this.http.get<any[]>(`/users/search?keyword=${keyword}`);
    }
    else { return this.http.get<any[]>(`/users/search?keyword=${keyword}&statusFilter=${status}`); }
  }
  public isLeaderOfProject(project) {
    return this.authService.getToken().pipe(
      switchMap((data: any) => {
        if (data && data.payload) {
          const username = data.payload.preferred_username.toUpperCase();
          const user = project.project_user_association_v.filter(
            item =>
              item.username.toString().toUpperCase() === username && item.user_type.toString().toUpperCase() === 'LEAD'
          );
          return of(!!user.length);
        }
      })
    );
  }

  public getProfile(): Observable<any> {
    if (!this.cache$.getProfile) {
      this.cache$.getProfile = this.http.get(`users/profile`).pipe(shareReplay(1));
    }
    return this.cache$.getProfile;
  }

  public getcurrentUser() {
    return this.http.get(`users/profile`);
  }

  public getAccountManagers() {
    return this.http.get(`users/managers`);
  }

  public getApprovedUsers() {
    return this.http.get(`users/?status=approved`);
  }

  public getTerms() {
    return this.http.get(`/terms/`).pipe(map((data: any) => data.terms));
  }
  public getConfigurations(collection: any, configuration: any = '') {
    return this.http.get(`/users/configurations/${collection}${configuration ? '/' + configuration : ''}`);
  }

  public termsAccepted() {
    return this.http.get(`/terms/verify`, { observe: 'response' })
      .pipe(
        map((response:any) => {
          return { isError: !response.body, status: response.statusCode || response.status };
        }),
        catchError((error) => {
          return of({ isError: true, status: error.statusCode || error.status });
        })
      );
  }

  public acceptUpdatedTerms(terms) {
    return this.http.post(`/users/terms`, terms);
  }

  public getUserAttribute(key) {
    return this.http.get(`users/attributes/${key}`)
  }

  public saveUserAttribute(payload) {
    return this.http.post(`users/attributes`, payload);
  }
  public getProfileGroups() {
    return this.http.get<any[]>(`users/group`);
  }  
  public isUserInGroup(userGroups: string[], checkGroup: string): boolean {
    const length = userGroups.length;
    for (let i = 0; i < length; i++) {
      if (userGroups[i].includes(checkGroup)) { return true; }
    }
    return false
  }
}
