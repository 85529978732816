import { Component, HostBinding, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CONFIRM_BOX_PARAMS } from './confirm-box.token';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ds365-confirm-box',
  template: `
    <div class="container mt-30">
      <div *ngIf= "params.title" class="modal-header">
      <h4 class="modal-title">{{ params.title }}</h4>
    </div>
      <div class="row  mt-30">
        <div class="col " [ngClass]="params.textClass ? params.textClass : 'text-center'">
          <h4 class="text-white multiline-text" [ngClass]="params.class" [innerHTML]="params.text"></h4>
          <a *ngIf="params.url" [href]="params.url" target="_blank">{{ params.linkText }}</a>
          <div class="remark-container" *ngIf="params.isInput">
            <label for="ta-remark">Remarks<span class="text-danger">*</span></label>
            <ds365-text-area
              id="ta-remark"
              [group]="form"
              controlName="remark"
              requiredMsg="Remark is required"
              [maxLength]="1024"
              placeholder="{{params.placeholder}}"
              [rows]="4">
              <ng-container note><i>{{ params.helpText }}</i></ng-container>
            </ds365-text-area>
          </div>
          <div *ngIf="params.checkBox" class="custom-control custom-control-inline custom-checkbox text-white mt-2">
            <input class="custom-control-input" type="checkbox" id="cbox-check" [value]="true"
            (change)="onCheckboxChange($event)" />
            <label class="custom-control-label" for="cbox-check">{{ params.checkBox }}</label>
          </div>
          <div class="clearfix"></div>
         <div  class= ''>
        <button 
          *ngIf="params.acceptText"
          [disabled]="isAcceptDisabled()"
          ds365Button
          [space]="true"
          status="primary"
          id="confirm-accepts"
          [fullWidth]="true"
          (click)="accepted()">
          {{ params.acceptText }}
        </button>
        <button
          ds365Button
          status="white"
          [bordered]="true"
          id="confirm-rejects"
          [fullWidth]="true"
          (click)="rejected()">
          {{ params.rejectText }}
        </button>
      </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
       
      }
    .border{
     border: 2px solid;
    }
      button {
        max-width: 250px !important;
        margin: 1rem auto !important;
      }
      .multiline-text {
        white-space: pre-line;
        line-height: 200%;
      }
      .form-control {
        min-width: 80vw;
        min-height: 150px;
        border: solid;
      }
      .mt-30 {
        margin-top: 20px;
      }
      textarea {
        border: 0px;
        width: 100%;
        height: 100%;
      }
      .remark-container {
        min-width: 600px;
        text-align: left;
      }
      .remark-container label {
        float: none;
      }
      textarea:focus,
      textarea:active {
        border: 0px;
        outline: none;
      }
    .additional-class {
     font-size: larger;
     white-space: normal !important;
    }
    .text-justify {
    text-align: justify !important;
    }
    
    .btnSame{
    display:inline !important;
    }
    .btn-container {
    margin-top:1rem;
    display: flex;
    justify-content: center; 
    gap: 2rem; 
}
    .text-center
    { text-align: center !important;}

.btn-right {
  justify-content: flex-end;
}

.btn-same {
  display: inline-block !important;
  max-width: unset; /* Allow the buttons to fit content */
}
      @media only screen and (max-width: 640px) and (min-width: 320px) {
        .multiline-text {
          font-size: 14px !important;
          margin-top: 100px;
        }
      }
      @media (min-width: 768px) {
        .form-control {
          min-width: 600px;
        }
      }
    `,
  ],
})
export class ConfirmBoxComponent {
  public form: FormGroup;
  checkboxChecked = false;

  constructor(private overlayRef: OverlayRef, @Inject(CONFIRM_BOX_PARAMS) public params: any, private fb: FormBuilder) {
    this.form = this.fb.group({
      remark: [null, [Validators.required]],
    });
  }
  onCheckboxChange(event: any) {
    this.checkboxChecked = event.target.checked;
    this.params.checBoxChange.next(event);
  }
  isAcceptDisabled(): boolean {
    return (this.params.isInput && !this.form.valid) || (this.params.ischeck && !this.checkboxChecked);
  }
  accepted() {
    if (this.params.isInput) {
      this.params.action.next(this.form.value.remark);
    } else {
      this.params.action.next(true);
    }
    
    this.overlayRef.dispose();
  }

  rejected() {
    this.params.action.next(false);
    this.overlayRef.dispose();
  }
  @HostBinding('class.border') get borderClass() {
    return this.params?.border === true; 
  }
}
